import {reactive, onMounted, onUnmounted} from "#imports";
import {Breakpoint} from "~/enums/layouts/Breakpoint";

export function useScreenWidthChecker() {

	const screenSize = reactive({
		isScreenUnderMd: false,
		isScreenUnderLg: false,
		isScreenUnderXl: false
	});
	const checkScreenWidth = () => {
		screenSize.isScreenUnderMd = window.innerWidth <= Breakpoint.MD;
		screenSize.isScreenUnderLg = window.innerWidth <= Breakpoint.LG;
		screenSize.isScreenUnderXl = window.innerWidth <= Breakpoint.XL;
	}

	onMounted(() => {
		checkScreenWidth();
		window.addEventListener('resize', checkScreenWidth);
	})

	onUnmounted(() => {
		window.removeEventListener('resize', checkScreenWidth);
	});

	return {
		screenSize,
		checkScreenWidth
	}
}
